<template>
  <div>
    <vs-chip v-for="group in data" :key="group.id" class="leading-none mt-3" transparent>
      <vs-icon icon="group" class="mr-1"/>
      {{ group.name }}
    </vs-chip>
  </div>
</template>

<script>
export default {
  name: 'UserListGroup',
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.con-vs-chip {
  font-size: 0.7rem;
}
</style>
