<template>
    <div class="flex" :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'UserListActions',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    editRecord () {
      this.$router.push({'name': 'user-edit', 'params': { userId: this.data.id }}).catch(() => {})
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('administration.userlist.table.actions.delete.dialog.title'),
        text: this.$t('administration.userlist.table.actions.delete.dialog.text', {username: this.data.username}),
        accept: this.deleteRecord,
        acceptText: this.$t('administration.userlist.table.actions.delete.dialog.acceptText'),
        cancelText: this.$t('administration.userlist.table.actions.delete.dialog.cancelText')
      })
    },
    deleteRecord () {
      this.$store.dispatch('userManagement/removeUser', this.data.id)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       }) // eslint-disable-line no-console
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: this.$t('administration.userlist.table.actions.delete.notify.title'),
        text: this.$t('administration.userlist.table.actions.delete.notify.text')
      })
    }
  }
}
</script>
