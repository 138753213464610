<template>
  <div class="flex items-center">
    <vs-avatar     :src="avatarSrc"
                   class="flex-shrink-0 mr-2"
                   size="30px"
                   :text="data.firstname + ' ' + data.lastname"
    />
    {{ data.username }}
  </div>
</template>

<script>
export default {
  name: 'UserListUsername',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    bearer () {
      return this.$store.getters['auth/accessToken']
    },
    avatarSrc () {
      if (!this.data.avatar && !this.data.fileAvatar) {
        return null
      }
      return `${this.data.fileAvatar}?bearer=${this.bearer}`
    }
  }
}
</script>
